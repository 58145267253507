import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";

export const countryAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<
  AxiosResponse<PaginatedResponse<{ code: string; name: string }[]>>
> => getPaginated(`client/${clientId}/country/autocomplete`, data);
