import { get } from "@/application/api";
import { AxiosResponse } from "axios";

export const getAddressInfo = ({
  postalCode,
  houseNumber,
  houseNumberAddition,
}: {
  postalCode: string;
  houseNumber: number | string;
  houseNumberAddition: string;
}): Promise<
  AxiosResponse<{
    data: {
      city: string;
      house_number: number;
      house_number_addition: number | string;
      postal_code: string;
      street: string;
    };
  }>
> =>
  get("/address/find", {
    params: { postalCode, houseNumber, houseNumberAddition },
  });
